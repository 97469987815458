<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-card class="ma-3">
      <v-card-title class="text-center grey white--text">
        お問い合わせフォーム
      </v-card-title>
      <v-text-field
        v-model="name"
        class="mt-10 mx-12"
        outlined="outlined"
        label="お名前（任意）"
        name="name"
        dense="dense"
      />
      <v-text-field
        v-model="email"
        class="mx-12"
        outlined="outlined"
        label="メールアドレス（任意）"
        name="email"
        dense="dense"
      />
      <v-textarea
        v-model="content"
        class="mx-12"
        :rules="contentRules"
        outlined="outlined"
        label="お問い合わせ内容"
        name="content"
      />
      <v-card-text>
        <v-row
          class="mx-8"
          justify="space-around"
        >
          <v-btn
            class="white--text"
            :disabled="!valid"
            block="block"
            color="orange"
            large="large"
            @click="sendForm"
          >
            フォームを送信する
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate="indeterminate"
        size="64"
      />
    </v-overlay>
    <v-snackbar
      v-model="success"
      bottom="bottom"
      color="success"
    >
      送信しました<template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="success = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="failed"
      bottom="bottom"
      color="error"
    >
      送信に失敗しました<template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="failed = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      loading: false,
      name: "",
      email: "",
      content: "",
      valid: false,
      success: false,
      failed: false,
      contentRules: [(v) => !!v || "お問い合わせ内容を入力してください"],
    };
  },
  computed: {
    slackMessage() {
      const msgText = `${this.name} ${this.email}\n${this.content}`;
      const msg = { text: msgText };
      return JSON.stringify(msg);
    },
  },
  methods: {
    async sendForm() {
      this.loading = true;

      try {
        await axios.post(
          "https://hooks.slack.com/services/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",
          this.slackMessage,
          {
            transformRequest: [
              (data, headers) => {
                delete headers.post["Content-Type"];
                return data;
              },
            ],
          }
        );
      } catch (error) {
        this.failed = true;
        console.error(error);
      } finally {
        this.loading = false;
        this.success = true;
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style scoped>
</style>
